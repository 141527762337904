import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        component: () =>
            import ('../layouts/MainLayout.vue'),
        children: [{
                path: '',
                name: 'Home',
                component: () =>
                    import ('../views/Home.vue'),
            },
            {
                path: 'terms',
                name: 'Terms',
                component: () =>
                    import ('../views/Terms.vue'),
            },
            {
                path: 'cookies',
                name: 'CookiesPolicy',
                component: () =>
                    import ('../views/Cookies.vue'),
            },
            {
                path: 'about',
                name: 'About',
                component: () =>
                    import ('../views/About.vue'),
            },
            {
                path: 'contact',
                name: 'Contact',
                component: () =>
                    import ('../views/Contact.vue'),
            },
            {
                path: 'report',
                name: 'Report',
                component: () =>
                    import ('../views/Reports.vue'),
            },
        ],
    },
    {
        path: '/panel',
        component: () =>
            import ('../layouts/PanelLayout.vue'),
        meta: { requiresAuth: true },
        children: [{
                path: '',
                name: 'PanelHome',
                component: () =>
                    import ('../views/PanelHome.vue'),
            },
            {
                path: 'statistics',
                name: 'Statistics',
                component: () =>
                    import ('../views/Statistics.vue'),
            },
            {
                path: 'profile',
                name: 'UserProfile',
                component: () =>
                    import ('../views/UserProfile.vue'),
            },

            {
                path: 'withdrawals',
                name: 'WithdrawalsUser',
                component: () =>
                    import ('../views/Withdrawals.vue'),
            }
        ],
    },
    {
        path: '/panel/admin',
        component: () =>
            import ('../layouts/admin/Layout.vue'),
        meta: { requiresAuth: true, requiresRole: 1 }, // Solo para usuarios con rol 1
        children: [{
                path: '',
                name: 'Dashboard',
                component: () =>
                    import ('../views/admin/Dashboard.vue'),
            },
            {
                path: 'links',
                name: 'Links',
                component: () =>
                    import ('../views/admin/Links.vue'),
            },
            {
                path: 'link/list',
                name: 'List',
                component: () =>
                    import ('../views/admin/List.vue'),
            },
            {
                path: 'users',
                name: 'Users',
                component: () =>
                    import ('../views/admin/Users.vue'),
            },
            {
                path: 'earnings',
                name: 'UsersEarnings',
                component: () =>
                    import ('../views/admin/UsersEarnings.vue'),
            },
            {
                path: 'harmonys',
                name: 'Harmonys',
                component: () =>
                    import ('../views/admin/Harmonys.vue'),
            },
            {
                path: 'stats',
                name: 'SiteStatistics',
                component: () =>
                    import ('../views/admin/SiteStatistics.vue'),
            },
            {
                path: 'withdrawals',
                name: 'Withdrawals',
                component: () =>
                    import ('../views/admin/Withdrawals.vue'),
            },

            {
                path: '/panel/admin/pendings',
                name: 'PendingWithdrawals',
                component: () =>
                    import ('../views/admin/PendingWithdrawals.vue'),
            }
        ],
    },
    {
        path: '/:slug',
        component: () =>
            import ('../layouts/HarmonyLayout.vue'),
        children: [{
                path: '',
                name: 'Harmony',
                component: () =>
                    import ('../views/Harmony.vue'),
            },
            {
                path: 'editor',
                name: 'HarmonyEditor',
                component: () =>
                    import ('../views/Harmony.vue'),
            },
        ],
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

// Navigation guard to protect routes
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresRole = to.matched.some(record => record.meta.requiresRole);
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    // Si la ruta requiere autenticación
    if (requiresAuth && (!user || !token)) {
        next('/'); // Redirige al home si no está autenticado
    } else if (requiresRole && user.role_id !== 1) {
        next('/'); // Redirige al home si el usuario no tiene rol 1
    } else {
        next(); // Permite la navegación si todo está en orden
    }
});

export default router;